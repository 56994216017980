import * as Sentry from '@sentry/sveltekit';
import { init } from '@spotlightjs/spotlight';
import { dev } from '$app/environment';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.

if (dev) {
	init({
		injectImmediately: true
	});
	Sentry.init({
		dsn: 'https://7309d6eeb4295cad17b1846a91574f19@o4505681045815296.ingest.sentry.io/4505761093844992',
		tracesSampleRate: 1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
		integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
	});
}

export const handleError = Sentry.handleErrorWithSentry();
