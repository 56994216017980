import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [~6],
		"/(authed)/customers/list": [~8,[2,3]],
		"/(authed)/customers/upcomingFollowUp": [~9,[2,3]],
		"/(authed)/customers/[id]": [7,[2,3]],
		"/(authed)/dashboard/overview": [~10,[2,4]],
		"/(authed)/dashboard/pendingDelivery": [~11,[2,4]],
		"/(authed)/dashboard/retention": [~12,[2,4]],
		"/(authed)/dashboard/todayDelivery": [~13,[2,4]],
		"/(authed)/logout": [14,[2]],
		"/(authed)/orderHistory": [~15,[2]],
		"/(authed)/orderHistory/[id]": [16,[2]],
		"/(authed)/team": [~17,[2]],
		"/(authed)/team/[id]": [18,[2]],
		"/(authed)/v2": [19,[5]],
		"/(authed)/v2/wa/bulk-messages": [20,[5]],
		"/(authed)/v2/wa/customer-announcement": [~21,[5]],
		"/(authed)/v2/wa/customer-announcement/new": [~22,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';